/* MainContent.css */
.inner_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 2rem 0;
  }
  
  .paragraph {
    margin: 1rem 0;
  }
  
  .image_holder {
    width: 100%;
  }
  
  .image_holder img {
    width: 100%;
  }
  
  .info_container, .form_container {
    width: 50%;
    padding: 1rem;
  }
  
  .form_container {
    background-color: var(--bg-color1);
    border-radius: 1rem;
  }
  
  .form_header{
    padding: 2rem 0;
    font-weight: 800;
    font-size:1.3rem
  }
   
  .form-body {
    display: flex;
    flex-direction: column;
  }
  .form-body-content {
    display: flex;
    flex-wrap: wrap;
  }
  
  .form-body-content div{
    width: 50%;
  }
  
  
  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pickup_time_container p{
    padding: 1.2rem 0;
    font-weight: 600;
  }
  button {
    padding: 10px;
    background-color: var(--primary_color2);
    color: #FFF;
    box-shadow: none;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  button:hover{
    background-color: var(--primary_color1);
  }
  .btn-small{
    width:  inline-block;;
    padding: 10px;
    white-space: nowrap;
  }
  .btn-medium{
    width:  inline-block;;
    padding: 10px 6rem;
    white-space: nowrap;
  }
  .btn-remove{
    background-color: #FFF;
  }
  .pickup_time_container{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .date_container{
    display: flex;
    width: 100%;

  }
  .pickup_time{
    display: flex;
    flex-wrap: wrap;
  }
  .pickup_time p {
    max-width: 50%;
  } 
  .picker_details{
    display: flex;
    flex-wrap: wrap;
  }
  .picker_details p{
    width: 50%;
  }
  .separator{
    margin: 1rem 1rem 1rem 0;
  }

  .invoice-item {
    transition: opacity 0.3s ease-out;
  }
  
  .removed {
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .error-message{
    color: var(--primary_color1);
  }
  
 
  @media (max-width: 600px) {
    .inner_container {
      flex-direction: column;
    }
  
    .info_container,
    .form_container {
      width: 100%;
    }
    .form-body-content input[type="text"], .form-body-content div{
      min-width: 43%;
    }
    .form-body-content div{
      width: auto;
    }
    .form_container {
      background-color: var(--bg-color1);
      border-radius: 0;
    }
  
    /* button {
      width: 100%;
    } */
    .btn-small{
      width:  inline-block;;
      padding: 10px;
      white-space: nowrap;
    }
    .btn-medium{
      width:  inline-block;;
      padding: 20px 6rem;
      white-space: nowrap;
    }
    .btn-remove{
      background-color: #FFF;
    }
    .picker_details p {
      width: 40%;
    }
    .picker_details input[type="text"], .picker_details input[type="email"]{
      width: 55%;
    }
    
  }