#root{
    width: 100%!important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .splash {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10%;
  }
  .splash p{
    margin: 20px 0;
  }