@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,900;1,100;1,700&display=swap');
@import '@fortawesome/fontawesome-svg-core/styles.css';

:root {
    --primary_color1: #F26178;
    --primary_color2: #005470; 
    --bg-color1: #FBFAF9; 
    --light-grey:#b5b5b3;
    --grey:#969799;
    --grey2:#f5f4f1;
    --text:#29233E;
    --input-background:#f5f4f1;

  }
*{
    padding: 0;
    margin: 0;
   
    box-sizing: border-box;
}
body{
    margin: 0%;
    color: var(--text);
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}
#root{
  width: 100%!important;
  display: flex;
  justify-content: center;
  align-items: center;
}
input[type="text"], [type="time"], select,[type="email"]  {
    padding: 15px;
    border: none;
    background-color: #fff;
    border-radius: 5px;
    outline: none;
    width: 40%;

  }
  
  input[type="date"]{
    max-width:200px;
    border: none; 
    font-family:  'Inter', sans-serif;
    color: var(--text);
    border-radius: 5px;
    outline: none;
    padding: 15px;
  }
  select{max-width:200px ; color: var(--text);;}

.container{
    max-width: 1300px;
}

@media (max-width: 600px) {
 /*  input[type="text"] {
    width: 80%;
    margin: 5px 0;
    padding: 20px 10px;
  } */

  input[type="date"]{
    max-width:50%;
    border: none; 
    font-family:  'Inter', sans-serif;
    color: var(--text);
    border-radius: 5px;
    outline: none;
    padding:15px;
  }
}