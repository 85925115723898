/* Footer.css */
footer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: var(--grey2);
    padding: 2rem;
    font-size: 16px;
    color: var(--grey);
  }
  footer a{
    text-decoration: none;
    color: var(--primary_color1);
  }
  footer h3 {
    color: var(--primary_color2);
  }
  
  .footer_one {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1rem;
  }
  
  .f_one_sec_two ul, .f_two_sec_one ul {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem 0;
  }
  
  .f_two_sec_one div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .f_two_sec_one div ul li {
    margin: 10px 0;
  }
  
  .f_one_sec_two ul li {
    margin: 10px 0;
    font-weight: 500;
  }
  
  .footer_one, .footer_two, .f_one_sec_one, .f_one_sec_two {
    width: 50%;
  }
  
  .social {
    display: flex;
    justify-content: flex-start;
    padding: 3rem 2rem;
    align-items: center;
  }
  
  .social img {
    margin: 0 10px;
  }
  
  .newsletter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .newsletter li {
    width: 50%;
    list-style: none;
    margin-right: 0;
  }
  
  .newsletter li input[type="text"] {
    background-color: #fff;
    width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 1rem;
  }
  
  .newsletter li button {
    background-color: var(--primary_color1);
    width: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 1rem;
  }

  @media (max-width: 600px) {
    footer,
    .footer_one,
    .footer_two {
      padding: 1rem;
      display: flex;
      flex-direction: column;
    }
    .f_one_sec_one {
    display: flex;
    flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .footer_one,
    .footer_two,
    .f_one_sec_one,
    .f_one_sec_two {
      width: 100%;
    }
    .newsletter{
        order:1;
    }
    .f_two_sec_one {
        display: flex;
        flex-direction: column-reverse; /* Reverses the order of the children within .f_two_sec_one */
      }
    .social{
        justify-content: center;
    }  

    .f_one_sec_two ul{
        justify-content: center;
    }
    
  }
  