/* Header.css */

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .logo {
    width: auto;
    height: auto;
  }
  
  .menu_wrapper {
    flex-grow: 1;
    margin-left: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .menu-desktop a{
    text-decoration: none;
    font-weight: 600;
  }
  .menu-desktop a:hover{
    color:#F26178;
  }
  .hamburger-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  .menu-mobile {
    display: none;
  }
  
  .hamburger-bar {
    width: 30px;
    height: 3px;
    background-color: #000;
    margin: 3px 0;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  li {
    margin-right: 20px;
  }


  @media (max-width: 600px) {
    .header {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .menu_wrapper {
      margin: 20px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    .menu-mobile {
      display: block;
    }
    .close-icon{
        font-size: 18px;
        font-weight: 800;
    }
    .menu-desktop {
      display: none;
    }
  
    ul {
      flex-direction: column;
      text-align: center;
    }
  
    li {
      margin: 10px 0;
    }

  }